class Participant {
  constructor(name, phone, email, age, work, telegram) {
    this.name = name;
    this.phone = phone;
    this.email = email;
    this.age = age;
    this.work = work;
    this.telegram = telegram;
  }

  setFieldData(name, value) {
    this[name] = value;
  }

  clear() {
    this.name = "";
    this.phone = "";
    this.email = "";
    this.age = "";
    this.work = "";
    this.telegram = "";
  }

  get data() {
    return {
      name: this.name,
      phone: this.phone,
      email: this.email,
      age: this.age,
      work: this.work,
      telegram: this.telegram,
    };
  }

  get isValid() {
    return (
      this.name &&
      this.phone &&
      this.email &&
      this.age &&
      this.work &&
      this.telegram
    );
  }
}

class ParticipantsForm {
  constructor(formEl) {
    this.form = formEl;
    this.participants = [...new Array(5)].map(() => new Participant());
    this.currentParticipantIndex = 0;
    this.skill = "professional";
    this.initEventsListeners();
  }

  fillForm(data) {
    this.form.querySelectorAll(".textfield").forEach((field) => {
      field.value = data[field.name] || "";
    });
  }

  setParticipantButtonStatus(isValid = false, index) {
    this.form
      .querySelector(
        `.participant-button[data-index="${index || this.currentParticipantIndex}"]`,
      )
      .classList.toggle("success", isValid);
  }

  setCurrentParticipantIndex(index) {
    this.currentParticipantIndex = index;

    this.form.querySelectorAll(".participant-button").forEach((button) => {
      button.classList.remove("active");
    });
    this.form
      .querySelector(`.participant-button[data-index="${index}"]`)
      .classList.add("active");
  }

  handleFieldChange(e) {
    this.participants[this.currentParticipantIndex].setFieldData(
      e.target.name,
      e.target.value,
    );

    this.setParticipantButtonStatus(
      this.participants[this.currentParticipantIndex].isValid,
    );
  }

  handleClickParticipant(e) {
    e.preventDefault();
    const index = e.currentTarget.dataset.index;

    if (e.target.classList.contains("participant-button__delete")) {
      e.stopPropagation();
      this.participants[index].clear();
      this.setParticipantButtonStatus(false, index);
    } else {
      this.setCurrentParticipantIndex(index);
    }

    this.fillForm(this.participants[this.currentParticipantIndex].data);
  }

  handleChangeSkill(e) {
    this.skill = e.target.value;
  }

  initEventsListeners() {
    this.form.querySelectorAll(".textfield").forEach((field) => {
      field.addEventListener("input", this.handleFieldChange.bind(this));
    });

    this.form.querySelectorAll(".participant-button").forEach((field) => {
      field.addEventListener("click", this.handleClickParticipant.bind(this));
    });

    this.form.querySelectorAll("input[name=skill]").forEach((field) => {
      field.addEventListener("change", this.handleChangeSkill.bind(this));
    });
  }

  clearEventsListeners() {
    this.form.querySelectorAll(".textfield").forEach((field) => {
      field.removeEventListener("input", this.handleFieldChange.bind(this));
    });

    this.form.querySelectorAll(".participant-button").forEach((field) => {
      field.removeEventListener(
        "click",
        this.handleClickParticipant.bind(this),
      );
    });

    this.form.querySelectorAll("input[name=skill]").forEach((field) => {
      field.removeEventListener("change", this.handleChangeSkill.bind(this));
    });
  }

  clearSkills() {
    this.skill = "professional";
    this.form.querySelector("input[name=skill][value=professional]").checked =
      true;
  }

  clearParticipantsButtons() {
    this.form.querySelectorAll(".participant-button").forEach((button) => {
      button.classList.remove("success");
    });
  }

  clear() {
    this.clearEventsListeners();
    this.fillForm({});
    this.clearParticipantsButtons();
    this.setCurrentParticipantIndex(0);
    this.clearSkills();
  }

  get data() {
    return {
      participants: this.participants.map((item) => item.data),
      skill: this.skill,
    };
  }
}

const initMobileMenu = () => {
  document.querySelector(".header__menu-btn")?.addEventListener("click", () => {
    document.documentElement.classList.toggle("menu-active");
  });
};

const initScrollBtn = () => {
  document
    .querySelector(".footer__scroll-btn")
    ?.addEventListener("click", () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
};

const initRegistrationModal = () => {
  let participantsForm = null;

  const initParticipantsForm = (form) => {
    participantsForm = new ParticipantsForm(form);
  };

  const clearParticipantsForm = () => {
    participantsForm.clear();
    participantsForm = null;
  };

  document.querySelectorAll(".js-event-button")?.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.stopPropagation();
      e.preventDefault();
      const { title, formType } = e.currentTarget.dataset;
      const modal =
        formType === "extended"
          ? document.querySelector("#event-registration-extended")
          : document.querySelector("#event-registration");
      const modalTitle = modal?.querySelector(".form__title");
      const form = modal?.querySelector(".form");

      if (modalTitle) {
        modalTitle.innerHTML = title;
      }

      if (formType === "extended" && form) {
        initParticipantsForm(form);
      }

      modal?.classList.add("active");
      document.documentElement.classList.add("modal-active");
    });
  });

  document.querySelectorAll(".js-modal-close")?.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.currentTarget.closest(".modal").classList.remove("active");
      document.documentElement.classList.remove("modal-active");

      if (participantsForm) {
        clearParticipantsForm();
      }
    });
  });

  document.querySelector(".form")?.addEventListener("submit", (e) => {
    e.preventDefault();
    e.currentTarget.classList.add("hasResult");

    if (participantsForm) {
      console.log("data => ", participantsForm.data);
      clearParticipantsForm();
    }
  });

  document
    .querySelector(".form__result-close")
    ?.addEventListener("click", (e) => {
      e.preventDefault();
      e.currentTarget.closest(".form").classList.remove("hasResult");
      e.currentTarget.closest(".modal").classList.remove("active");
      document.documentElement.classList.remove("modal-active");
    });
};

window.onload = () => {
  initMobileMenu();
  initScrollBtn();
  initRegistrationModal();
};
